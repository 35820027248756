<template>
	<div>
	  <vs-table
		search
		stripe
		border
		description
		:sst="true"
		:data="table.data"
		:max-items="table.length"
		:total="table.total"
		@search="handleSearch"
		@change-page="handleChangePage"
		@sort="handleSort"
	  >
		<template slot="header">
		  <vs-dropdown vs-trigger-click class="cursor-pointer">
			<div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
			  <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
			  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
			</div>
			<vs-dropdown-menu>
			  <vs-dropdown-item
				v-for="item in table.limits"
				:key="item"
				@click="handleChangelength(item)"
			  >
				<span>{{ item }}</span>
			  </vs-dropdown-item>
			</vs-dropdown-menu>
		  </vs-dropdown>
		  &nbsp;		  
		<vs-button
			v-if="!selectedHeadData.export_file_path"  	
			color="black"
			icon-pack="feather"
			@click="generate()"
		>Generate</vs-button>
		<vs-button
		  	v-else
			color="grey"
			icon-pack="feather"
			@click.stop="downloadFileAwsS3(selectedHeadData.export_file_path)"
		>Download</vs-button>
		</template>
		<template slot="thead">
		  <vs-th>Sales Order Number</vs-th>
		  <vs-th>Product</vs-th>
		  <vs-th>Qty Sold</vs-th>
		  <vs-th>Conversion</vs-th>
		  <vs-th sort-key="status">Status</vs-th>
		  <vs-th>Remark Mandatory Field</vs-th>
		  <vs-th>Remark Validation Master</vs-th>
		  <vs-th>Remark Create SO</vs-th>
		</template>
		<template slot-scope="{ data }">
		  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
			<vs-td>{{ tr.salesorderNumber }}</vs-td>
			<vs-td>{{ tr.productCode + (tr.line_conversion ? " - " + tr.line_conversion[0].item_name : "") }}</vs-td>
			<vs-td>{{ tr.qtySold }}</vs-td>
			<vs-td>
				<div :key="indextd" v-for="(td, indextd) in tr.line_conversion">
					<p>{{ td.quantity }} {{ td.unit_name }}</p>
				</div>
			</vs-td>
			<vs-td>{{ tr.status && tr.status.trim() !== "" ? tr.status : "Processing" }}</vs-td>
			<vs-td>{{ tr.remark_mandatory_field != "" ? tr.remark_mandatory_field : "-" }}</vs-td>
			<vs-td>{{ tr.remark_validation_master != "" ? tr.remark_validation_master : "-" }}</vs-td>
			<vs-td>{{ tr.remark_create_co && tr.remark_create_co.trim() !== "" ? tr.remark_create_co : "-" }}</vs-td>
		  </vs-tr>
		</template>
	  </vs-table>
	  <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
	</div>
</template>
  
<script>
  import moment from 'moment';
  
  export default {
	props: ["selected", "selectedData", "selectedHeadData"],
	mounted() {
	  this.setDataFromSelectedData(); // Set initial data on mount
	},
	data() {
	  return {
		table: {
		  data: [],
		  length: 10, // Default to 10 items per page
		  page: 1, // Default to the first page
		  search: "",
		  order: "id",
		  sort: "asc",
		  total: 0,
		  totalPage: 0,
		  totalSearch: 0,
		  limits: [10, 25, 50, 100, "All"],
		  start: 1,
		  end: 0,
		  id: 0,
		},
	  };
	},
	methods: {
	  dateFormatGMT7(date) {
		if (date) {
		  return moment(String(date)).format('DD/MM/YYYY HH:mm');
		}
	  },
	  handleSearch(searching) {
		this.table.search = searching;
		this.table.page = 1; // Reset to the first page after search
		if (this.table.search.length > 0) {
		  this.filterData(); // Filter the data based on the search query
		} else {
          this.table.length = this.table.limits[0];
		  this.setDataFromSelectedData(); // Update the data without filtering
		}
	  },
	  handleChangePage(page) {
		this.table.page = page;
		this.updateData(); // Update the start and end based on the page change
	  },
	  handleSort(key, active) {
		this.table.order = key;
		this.table.sort = active;
		this.sortData(); // Sort the data based on the selected column
	  },
	  handleChangelength(val) {
		this.table.length = val === "All" ? this.table.total : val;
		this.table.page = 1; // Reset to the first page when changing the length
		this.updateData(); // Update start and end after changing the page size
	  },
	  setDataFromSelectedData() {
		console.log("====================")
		console.log(this.selectedHeadData)
		console.log(this.selectedData)
		this.table.data = this.selectedData;
		this.table.total = this.selectedData.length;
		// this.updateStartEnd(); // Update the start and end for pagination
		this.updateData(); // Ensure data is paginated correctly
	  },
	  filterData() {
		// Filter the selected data based on the search term
		const filteredData = this.selectedData.filter(item => {
			// Check if fields are undefined or null, and use empty string if so
			const salesorderNumber = item.salesorderNumber ? item.salesorderNumber.toLowerCase() : '';
			const productCode = item.productCode ? item.productCode.toLowerCase() : '';
			const qtySold = item.qtySold ? item.qtySold.toLowerCase() : '';
			const status = item.status ? item.status.toLowerCase() : '';

			return (
				salesorderNumber.includes(this.table.search.toLowerCase()) ||
				productCode.includes(this.table.search.toLowerCase()) ||
				qtySold.includes(this.table.search.toLowerCase()) ||
				status.includes(this.table.search.toLowerCase())
			);
		});
		
		this.table.data = filteredData;
		this.table.length = filteredData.length;
		this.table.total = filteredData.length;
		this.updateStartEnd(); // Update start and end after filtering data
		// this.updateData(); // Ensure pagination still works after filtering
	  },	
	  sortData() {
		// Sort the data based on the selected sort key and order
		this.table.data.sort((a, b) => {
		  const aValue = a[this.table.order];
		  const bValue = b[this.table.order];
		  if (this.table.sort === 'asc') {
			return aValue > bValue ? 1 : -1;
		  } else {
			return aValue < bValue ? 1 : -1;
		  }
		});
		this.updateData(); // Ensure data is updated after sorting
	  },
	  updateData() {
		// Update the data according to the page and length
		const startIdx = (this.table.page - 1) * this.table.length;
		const endIdx = startIdx + this.table.length;
		const paginatedData = this.selectedData.slice(startIdx, endIdx);
		
		this.table.data = paginatedData;
		this.table.totalPage = Math.ceil(this.table.total / this.table.length); // Update total pages
		this.updateStartEnd(); // Update the start and end after changing data or pagination
	  },
	  updateStartEnd() {
		const valStart = this.table.length * this.table.page - this.table.length + 1;
		const valEnd = Math.min(this.table.length * this.table.page, this.table.total);
        this.table.totalPage = Math.ceil(this.table.total / this.table.length); // Update total pages
		this.table.start = valStart > this.table.total ? 0 : valStart;
		this.table.end = valEnd;
	  },
	  generate() {
		this.$http
			.get("/api/v1/import/cmi/generate", {
				params: {
					id: this.selected,
				},
			})
			.then((resp) => {
				if (resp.code == 200) {
					this.$vs.notify({
						color: "primary",
						title: "Success",
						text: "Please wait background process is running...",
						position: "top-right",
						iconPack: "feather",
						icon: "icon-check-circle",
						time: 3000,
					});
				} else {
					this.$vs.notify({
						color: "danger",
						title: "Failed",
						text: resp.message,
						position: "top-right",
						iconPack: "feather",
						icon: "icon-x-circle",
						time: 10000,
					});
				}
			})
	  },
	},
	computed: {
	  setPage: {
		get() {
		  return this.table.page;
		},
		set(val) {
		  this.handleChangePage(val);
		},
	  },
	},
	watch: {
	  selectedData: function () {
		this.setDataFromSelectedData(); // Update data when selectedData changes
	  },
	},
  };
</script>

<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dropdown-menu {
  z-index: 99999999 !important;
}
</style>