<template>
	<div class="tab-text">
		<div class="vx-row mb-6" style="width: 50%">
			<div class="vx-col sm:w-1/6 w-full">
				<span>File</span>
			</div>
			<div class="vx-col sm:w-5/6 w-full">
				<input
				class="inputx"
				type="file"
				v-if="uploadReady"
				ref="file"
				name="file"
				accept=".txt"
				/>
			</div>
		</div>
		<div class="vx-row mb-6" style="width: 50%">
			<!-- <div class="vx-col sm:w-1/3 w-full flex items-center"></div> -->
			<div class="vx-col w-full">
				<vs-button class="mt-2" v-on:click="handleSubmit">Upload</vs-button>
			</div>
		</div>
		<div class="vx-row mb-6 ml-1 mr-1" style="border-top: 1px solid gray;">
		</div>
		<div class="vx-row" style="width: 50%">
			<div class="vx-col sm:w-1/6 w-full">
				<span>Date Upload</span>
			</div>
			<div class="vx-col sm:w-5/6 w-full">
				<div class="vx-row">
					<div class="vx-col" style="width: 50%">
						<date-range-picker
							style="min-height: 40px"
							class="w-full"
							ref="picker"
							opens="center"
							:locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
							:singleDatePicker="false"
							:timePicker="false"
							:timePicker24Hour="false"
							:showWeekNumbers="false"
							:showDropdowns="false"
							:autoApply="true"
							v-model="filter_date_upload"
							:linkedCalendars="true"
							>
							<template v-slot:input="picker" style="min-width: 350px">
								{{ dateFormat(picker.startDate) }} -
								{{ dateFormat(picker.endDate) }}
							</template>
						</date-range-picker>
					</div>
					<template>
						<vx-tooltip text="reset">
							<vs-button
								type="line"
								icon-pack="feather"
								icon="icon-refresh-cw"
								@click.stop="handleResetUploadDateFilter()"
							/>
						</vx-tooltip>
					</template>
				</div>
			</div>
		</div>
		<vs-alert v-if="errorMessage.length > 0" color="danger">
			<template v-for="dt in errorMessage">
				<div v-bind:key="dt" class="mb-2">{{ dt }}</div>
			</template>
		</vs-alert>
		<div class="vx-row mb-12">
			<div class="core vx-col md:w-1/1 w-full mb-base">
				<vs-table
					search
					stripe
					border
					description
					:sst="true"
					:data="table.data"
					:max-items="table.length"
					:total="table.total"
					@search="handleSearch"
					@change-page="handleChangePage"
					@sort="handleSort"
				>
					<template slot="header">
						<vs-dropdown vs-trigger-click class="cursor-pointer">
							<div class=" p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
								<span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
								<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
							</div>
							<vs-dropdown-menu>
								<vs-dropdown-item
									v-for="item in table.limits"
									:key="item"
									@click="handleChangelength(item)"
									>
									<span>{{ item }}</span>
								</vs-dropdown-item>
							</vs-dropdown-menu>
						</vs-dropdown>
					</template>
					<template slot="thead">
						<vs-th>No</vs-th>
						<vs-th sort-key="file">File</vs-th>
						<!-- <vs-th>Description</vs-th> -->
						<vs-th sort-key="date">Date Upload</vs-th>
						<vs-th>User</vs-th>
						<vs-th>Status</vs-th>
						<vs-th>Action</vs-th>
					</template>
					<template slot-scope="{ data }">
						<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
							<vs-td>{{ indextr + 1 }}</vs-td>
							<vs-td :data="data[indextr].file"><a :href="tr.file_path">{{ tr.file_name }}</a></vs-td>
							<!-- <vs-td :data="data[indextr].description">{{data[indextr].description}}</vs-td> -->
							<vs-td :data="tr.created_at">{{ dateFormatGMT7(tr.created_at) }}</vs-td>
							<vs-td>{{ tr.created_by }}</vs-td>
							<vs-td>
								Total Document : {{ tr.total_document_line }} <br>
								Document Process : {{ tr.total_document_line - (tr.document_activity_success + tr.document_activity_failed) }} <br>
								Document Success : {{ tr.document_activity_success }} <br>
								Document Failed : {{ tr.document_activity_failed }} <br>
								Detail Process : {{ tr.total_line - (tr.success_count + tr.failed_count) }} <br>
								Detail Success : {{ tr.success_count }} <br>
								Detail Failed : {{ tr.failed_count }}
							</vs-td>
							<vs-td>
								<vs-button class="mt-2 ml-2" color="primary" type="border" icon-pack="feather" icon="icon-search" @click="showDetail(tr)"></vs-button>
							</vs-td>
						</vs-tr>
					</template>
				</vs-table>
				<vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
			</div>
		</div>
		<vs-popup fullscreen title="Log Details" :active.sync="detail">
			<div class="vx-row mb-6">
				<div class="vx-col w-full" v-if="detail">
					<datatableDetail :selected="selected" :selectedData="selectedData" :selectedHeadData="selectedHeadData"></datatableDetail>
				</div>
			</div>
		</vs-popup>
  	</div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from 'moment'
import datatableDetail from "./datatable-detail.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
	components: {
		Datepicker,
		datatableDetail,
		DateRangePicker
	},
	mounted() {},
	data() {
		return {
			uploadReady: true,
			errorMessage: [],
			detail: false,
			selected: null,
			selectedData: [],
			selectedHeadData: [],
			filter_date_upload: {
				startDate: null,
				endDate: null,
			},
			startDate: null,
      		endDate: null,
			table: {
				data: [],
				length: 10,
				page: 1,
				search: "",
				order: "id",
				sort: "desc",
				total: 0,
				totalPage: 0,
				totalSearch: 0,
				limits: [10, 25, 50, 100, "All"],
				start: 1,
				end: 0,
			},
		};
	},
	methods: {
		dateFormat(date) {
			let a = null;
			if (date != null) {
				a = moment(date).format("DD/MM/YYYY");
			}
			return a;
		},
		handleResetUploadDateFilter(){
			this.filter_date_upload.startDate = null;
			this.filter_date_upload.endDate = null;
			this.startDate = "";
			this.endDate = "";
			this.getData()
		},
		getFilename(path) {
			return path.split("/")[5];
		},
		dateFormatGMT7(date) {
            if (date) {
				// ini karena datanya berformat `date time`, bukan `datetimezone`
				// jadi GMT(+7) ditambah +7 =>utc offset = 14
                return moment.utc(String(date)).format('DD/MM/YYYY HH:mm')
            }
        },
		async handleSubmit() {
			this.errorMessage = [];
			var file = this.$refs.file.files[0];
			if (file == null) {
				this.$vs.notify({
					color: "danger",
					title: "Form Validation",
					text: "File is required",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
			} else {
				//var dateNow = new Date();
				//var now =
				//dateNow.getFullYear() +
				//"" +
				//(dateNow.getMonth() + 1) +
				//"" +
				//dateNow.getDate();
				//now +=
				//+"" +
				//dateNow.getHours() +
				//"" +
				//dateNow.getMinutes() +
				//"" +
				//dateNow.getSeconds();
				this.formData = new FormData();
				this.formData.append("file", file);
				this.formData.append("user_agent", navigator.userAgent);
				this.formData.append("file_name", file.name);
				// this.formData.append("url", "/api/v1/import/new-so-to-inv");
				// this.formData.append("posting_date", moment(this.postingDate).format('YYYY-MM-DD'));
				
				// this.$vs.loading();
				this.$vs.notify({
					color: "success",
					title: "Success",
					text: "Processing your upload on backgound",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
				this.uploadReady = false;
				
				this.$http
				.post("/api/v1/import/cmi", this.formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					console.log(resp);
					this.$vs.loading.close();
					if (resp.status == "success") {
						this.getData();
					} else {
						this.errorMessage = resp.data;
						this.$vs.notify({
							color: "danger",
							title: "Error",
							text: resp.message,
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
					}
					this.$nextTick(() => {
						this.uploadReady = true;
					});
				})
				.catch((error) => {
					console.log(error);
					this.$vs.notify({
					color: "danger",
					title: "Error",
					text: error,
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
					});
				});
			}
		},
		handleSearch(searching) {
			this.table.search = searching;
			this.table.page = 1;
			this.getData();
		},
		closeDetail() {
			this.detail = false;
		},
		handleChangePage(page) {
			this.table.page = page;
			this.getData();
		},
		handleSort(key, active) {
			this.table.order = key;
			this.table.sort = active;
			this.getData();
		},
		handleChangelength(val) {
			this.table.length = val == "All" ? this.table.total : val;
			this.table.page = 1;
			this.getData();
		},
		showDetail(data) {
			this.selected = data.id;
			this.selectedHeadData = data
			let allLineSo = [];
			allLineSo = data.datas.map(dataItem => dataItem.line_so).flat();
			this.selectedData = allLineSo;
			this.detail = true;
		},
		getData() {
			this.$vs.loading();
			this.$http
				.get("/api/v1/import/cmi/log-import", {
					params: {
						length: this.table.length,
						page: this.table.page,
						search: this.table.search,
						order: this.table.order,
						sort: this.table.sort,
						type: "import-cmi",
						start_date: this.startDate,
						end_date: this.endDate,
					},
				})
				.then((resp) => {
				if (resp.status == "success") {
					this.table.total = resp.data.total_record;
					this.table.totalPage = resp.data.total_page;
					this.table.totalSearch = resp.data.total_record_search;
					this.table.length = resp.data.total_record_per_page;
					this.setStartEnd();
					const parsedRecords = resp.data.records.map(record => {
						try {
							const parsedDatas = JSON.parse(record.datas); // Parse 'datas' first
							const updatedDatas = parsedDatas.map(item => {
								try {
									const updatedLineSO = item.line_so.map(lineSo => {
										try {
											return {
												...lineSo,
												line_conversion: JSON.parse(lineSo.line_conversion) // Parse 'line_conversion' inside each 'line_so'
											};
										} catch (error) {
											console.error("Error parsing line_conversion for line_so:", lineSo, error);
											return { ...lineSo, line_conversion: null }; // Handle parsing error, set `line_conversion` to null
										}
									});
									return { ...item, line_so: updatedLineSO }; // Update the item with parsed 'line_so'
								} catch (error) {
									console.error("Error parsing line_so for item:", item, error);
									return { ...item, line_so: null }; // Handle parsing error, set `line_so` to null
								}
							});
							return { ...record, datas: updatedDatas }; // Update the record with the parsed and updated 'datas'
						} catch (error) {
							console.error("Error parsing datas for record:", record, error);
							return { ...record, datas: null }; // Handle parsing error, set `datas` to null
						}
					});
					const updatedRecords = parsedRecords.map(record => {
						try {
							// Reduce to calculate total_line, failed_count, and success_count
							const { totalLine, failedCount, successCount } = record.datas.reduce(
								(acc, dataItem) => {
									if (dataItem.line_so && Array.isArray(dataItem.line_so)) {
										acc.totalLine += dataItem.line_so.length;

										// Count statuses in line_so
										dataItem.line_so.forEach(line => {
											if (line.status === "Failed") {
												acc.failedCount++;
											} else if (line.status === "Imported") {
												acc.successCount++;
											}
										});
									}
									return acc;
								},
								{ totalLine: 0, failedCount: 0, successCount: 0 } // Initial accumulator values
							);

							// Return the updated record with new attributes
							return { 
								...record, 
								total_line: totalLine, 
								failed_count: failedCount, 
								success_count: successCount 
							};
						} catch (error) {
							// Handle errors gracefully
							return { 
								...record, 
								total_line: 0, 
								failed_count: 0, 
								success_count: 0 
							};
						}
					});
					console.log(updatedRecords)
					this.table.data = updatedRecords;
					this.$vs.loading.close();
				} else {
					console.log(resp.message);
				}
			});
		},
		setStartEnd() {
			let valStart = this.table.length * this.table.page - this.table.length + 1;
			if (valStart > this.table.total) {
				valStart = 1;
			}
			if (this.table.total == 0) {
				valStart = 0;
			}
			let valEnd = this.table.length * this.table.page;
			if (valEnd > this.table.total) {
				valEnd = this.table.total;
			}
			if (this.table.totalSearch < this.table.total) {
				valEnd = this.table.totalSearch;
			}

			this.table.start = valStart;
			this.table.end = valEnd;
		},
	},
  	watch: {
		filter_date_upload: function () {
			if (this.filter_date_upload.startDate != null) {
				this.startDate = moment(this.filter_date_upload.startDate).format(
				"YYYY-MM-DD"
				);
				this.endDate = moment(this.filter_date_upload.endDate).format(
				"YYYY-MM-DD"
				);
			}
			this.getData();
		},
	},
	computed: {
		setPage: {
			get() {
				return 1;
			},
			set(val) {
				this.handleChangePage(val);
			},
		},
	},
};
</script>

 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>